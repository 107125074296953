import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import "./AlertComponent.less";

export const alertTypes = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error"
};

const defaultConfig = {
  className: "alert-component",
  message: "primary",
  description: "",
  type: alertTypes.SUCCESS,
  showIcon: false,
  icon: "",
  closable: false
};

const AlertComponent = ({ config = defaultConfig, onClose = null }) => {
  const finalConfig = { ...defaultConfig, ...config };
  return (
    <Alert
      className={finalConfig ? finalConfig.className : ""}
      message={finalConfig ? finalConfig.message : ""}
      description={finalConfig ? finalConfig.description : ""}
      type={finalConfig ? finalConfig.type : ""}
      showIcon={finalConfig ? finalConfig.showIcon : ""}
      icon={finalConfig ? finalConfig.icon : ""}
      closable={finalConfig ? finalConfig.closable : ""}
      onClose={onClose}
    />
  );
};

AlertComponent.propTypes = {
  config: PropTypes.object,
  onClose: PropTypes.func
};

export default React.memo(AlertComponent);
