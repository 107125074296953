import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import { newCaseState, showDaysToSurgery, InfoCard } from "gsi-ui-components";

const CaseStageCard = ({ surgeryCaseState, surgeryDaySelected }) => {
  const statusLabel = surgeryCaseState || newCaseState.UNPLANNED;

  return (
    <InfoCard>
      <div className="surgery-date-container">
        <div
          className={
            "surgery-date" +
            (surgeryCaseState === newCaseState.PLANNED
              ? " planned"
              : " unplanned")
          }
        >
          {surgeryDaySelected
            ? showDaysToSurgery(surgeryDaySelected)
            : "Select a surgery date"}
        </div>
        <Tag
          className={
            surgeryCaseState === newCaseState.PLANNED ? "planned" : "unplanned"
          }
        >
          {statusLabel}
        </Tag>
      </div>
    </InfoCard>
  );
};

CaseStageCard.propTypes = {
  surgeryCaseState: PropTypes.string,
  surgeryDaySelected: PropTypes.string
};

export default CaseStageCard;
