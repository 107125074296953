import React from "react";
import BaseButton from "../common/BaseButton";
import { BUTTON_CONSTS } from "../../helpers/consts";
import { getDownloadUrl, getFile } from "../../helpers/URLHelper";
import DownloadLink from "../common/DownloadLink";
import "./AdditionalMaterial.less";

const AdditionalMaterial = () => {
  const files = process.env.REACT_APP_DOWNLOAD_FILES ?? null;
  const parsedFiles = files && JSON.parse(files);
  const userManual = parsedFiles?.USER_MANUAL;
  const demoScan = parsedFiles?.DEMO_CT_SCANS;
  const videos = parsedFiles?.VIDEOS;

  return (
    <div className="additional-material">
      <label>Additional material</label>
      <a rel="noopener noreferrer" download className="user-manual-link">
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.DEFAULT,
            size: BUTTON_CONSTS.SIZE.DEFAULT,
            icon: "file-pdf"
          }}
          key={userManual.filename}
          text={userManual.text}
          onClick={() => getFile(getDownloadUrl(userManual.filename), "_blank")}
        />
      </a>
      <a rel="noopener noreferrer" download>
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.DEFAULT,
            size: BUTTON_CONSTS.SIZE.DEFAULT,
            icon: "file-zip"
          }}
          key={demoScan.filename}
          text={demoScan.text}
          onClick={() => getFile(getDownloadUrl(demoScan.filename))}
        />
      </a>
      <label className="videos-title">Videos</label>
      <div className="videos-wrapper">
        {videos.map(videoFile => {
          return (
            <DownloadLink
              key={videoFile.filename}
              downloadUrl={getDownloadUrl(videoFile.filename)}
              text={videoFile.text}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(AdditionalMaterial);
