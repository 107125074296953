export const getShareCaseInfo = uuid => async dispatch => {
  const [{ SHARED_CASE_INFO }, { getShareCaseInfoUrl }, { sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);
  try {
    dispatch({
      type: SHARED_CASE_INFO.LOADING
    });
    const response = await sendGetRequest(`${getShareCaseInfoUrl()}/${uuid}`);
    const attributes = response.data.data[0].attributes;
    dispatch({
      type: SHARED_CASE_INFO.SUCCESS,
      payload: attributes
    });
  } catch {
    dispatch({
      type: SHARED_CASE_INFO.ERROR
    });
  }
};

export const clearShareCaseInfo = () => async dispatch => {
  const { SHARED_CASE_INFO } = await import(`../helpers/types`);
  dispatch({
    type: SHARED_CASE_INFO.CLEAR
  });
};

export const getSharedByInfo = caseNumber => async dispatch => {
  const [{ SHARED_BY_INFO }, { getSharedCaseInfoUrl }, { sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);
  try {
    dispatch({
      type: SHARED_BY_INFO.LOADING
    });
    const response = await sendGetRequest(getSharedCaseInfoUrl(caseNumber));

    const attributes =
      response.data?.included && response.data?.included[0].attributes;

    dispatch({
      type: SHARED_BY_INFO.SUCCESS,
      payload: attributes
    });
  } catch {
    dispatch({
      type: SHARED_BY_INFO.ERROR
    });
  }
};
