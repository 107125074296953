export const LOCATION_CHANGED = "LOCATION_CHANGED";

export const CASE_COUNT = {
  LOADING: "CASE_COUNT_LOADING",
  SUCCESS: "CASE_COUNT_SUCCESS",
  ERROR: "CASE_COUNT_ERROR"
};

export const CASE_LIST = {
  LOADING: "CASE_LIST_LOADING",
  SUCCESS: "CASE_LIST_SUCCESS",
  ERROR: "CASE_LIST_ERROR",
  CLEAR: "CASE_LIST_CLEAR",
  GET_TOTAL_ITEMS: "CASE_LIST_GET_TOTAL_ITEMS",
  FIRST_PAGE: "CASE_LIST_FIRST_PAGE",
  FIRST_PAGE_STATUS: "FIRST_PAGE_STATUS"
};

export const CASE_INFO = {
  LOADING: "CASE_INFO_LOADING",
  SUCCESS: "CASE_INFO_SUCCESS",
  ERROR: "CASE_INFO_ERROR",
  CLEAR: "CASE_INFO_CLEAR"
};

export const TRIGGER_SHARE_CASE = {
  INIT: "TRIGGER_SHARE_CASE_INIT",
  SUCCESS: "TRIGGER_SHARE_CASE_SUCCESS",
  ERROR: "TRIGGER_SHARE_CASE_ERROR",
  CLEAR: "TRIGGER_SHARE_CASE_CLEAR",
  UPDATE_REF: "TRIGGER_SHARE_CASE_UPDATE_REF"
};

export const PLANNING_INFO = {
  LOADING: "PLANNING_INFO_LOADING",
  SUCCESS: "PLANNING_INFO_SUCCESS",
  ERROR: "PLANNING_INFO_ERROR",
  CLEAR: "PLANNING_INFO_CLEAR"
};

export const TICKET_LIST = {
  LOADING: "TICKET_LIST_LOADING",
  SUCCESS: "TICKET_LIST_SUCCESS",
  ERROR: "TICKET_LIST_ERROR",
  CLEAR: "TICKET_LIST_CLEAR"
};

export const SHARED_CASE_INFO = {
  LOADING: "SHARED_CASE_INFO_LOADING",
  SUCCESS: "SHARED_CASE_INFO_SUCCESS",
  ERROR: "SHARED_CASE_INFO_ERROR",
  CLEAR: "SHARED_CASE_INFO_CLEAR"
};

export const SHARED_BY_INFO = {
  LOADING: "SHARED_BY_INFO_LOADING",
  SUCCESS: "SHARED_BY_INFO_SUCCESS",
  ERROR: "SHARED_BY_INFO_ERROR"
};

export const TIGGER_SHARE_CASE = {
  INIT: "TIGGER_SHARE_CASE_INIT",
  SUCCESS: "TIGGER_SHARE_CASE_SUCCESS",
  ERROR: "TIGGER_SHARE_CASE_ERROR"
};

export const SERIES_SCANS_OUTPUT = {
  SUCCESS: "SERIES_SCANS_OUTPUT_SUCCESS",
  LOADING: "SERIES_SCANS_OUTPUT_LOADING",
  ERROR: "SERIES_SCANS_OUTPUT_ERROR",
  CLEAR: "SERIES_SCANS_OUTPUT_CLEAR",
  CLEAN: "SERIES_SCANS_OUTPUT_CLEAN"
};

export const CT_SCAN = {
  SUCCESS: "CT_SCAN_SUCCESS",
  LOADING: "CT_SCAN_LOADING",
  ERROR: "CT_SCAN_ERROR",
  RESET: "CT_SCAN_RESET"
};

export const LOAD_IMPLANTS_INFO = {
  SUCCESS: "LOAD_IMPLANTS_INFO_SUCCESS",
  LOADING: "LOAD_IMPLANTS_INFO_LOADING",
  ERROR: "LOAD_IMPLANTS_INFO_ERROR"
};

export const WEBSOCKET_CONNECT = {
  INIT: "WEBSOCKET_CONNECT_INIT",
  SUCCESS: "WEBSOCKET_CONNECT_SUCCESS",
  ERROR: "WEBSOCKET_CONNECT_ERROR"
};

export const WEBSOCKET_DISCONNECT = {
  INIT: "WEBSOCKET_DISCONNECT_INIT",
  SUCCESS: "WEBSOCKET_DISCONNECT_SUCCESS",
  ERROR: "WEBSOCKET_DISCONNECT_ERROR"
};

export const WEBSOCKET_MESSAGE = {
  UPDATE: "WEBSOCKET_MESSAGE_UPDATE",
  CLEAR: "WEBSOCKET_MESSAGE_CLEAR"
};

export const SHARE_CASE_STATUS = {
  DEFAULT: "SHARE_CASE_DEFAULT",
  LOADING: "SHARE_CASE_LOADING",
  SUCCESS: "SHARE_CASE_SUCCESS",
  ERROR: "SHARE_CASE_ERROR",
  CLEAR: "SHARE_CASE_CLEAR"
};

export const NEW_FEATURES = {
  LOADING: "NEW_FEATURES_LOADING",
  SUCCESS: "NEW_FEATURES_SUCCESS",
  ERROR: "NEW_FEATURES_ERROR",
  CLEAR: "NEW_FEATURES_CLEAR"
};
