import { DEFAULT_ERROR_MESSAGE } from "../helpers/consts";

export const triggerShareCase = uuid => async dispatch => {
  const [{ TRIGGER_SHARE_CASE }, { getShareCaseInfoUrl }, { sendPutRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);

  try {
    dispatch({
      type: TRIGGER_SHARE_CASE.INIT
    });
    await sendPutRequest(`${getShareCaseInfoUrl()}/${uuid}`);
    dispatch({
      type: TRIGGER_SHARE_CASE.SUCCESS,
      payload: uuid
    });
  } catch (error) {
    dispatch({
      type: TRIGGER_SHARE_CASE.ERROR,
      payload:
        error.response.status === 409
          ? error.response.data.message ?? DEFAULT_ERROR_MESSAGE
          : DEFAULT_ERROR_MESSAGE
    });
  }
};

export const triggerShareClear = () => async dispatch => {
  const { TRIGGER_SHARE_CASE } = await import(`../helpers/types`);
  dispatch({
    type: TRIGGER_SHARE_CASE.CLEAR
  });
};

export const updateCloneMessageRef = uuid => async dispatch => {
  const { TRIGGER_SHARE_CASE } = await import(`../helpers/types`);
  dispatch({
    type: TRIGGER_SHARE_CASE.UPDATE_REF,
    payload: uuid
  });
};
