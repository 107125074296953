import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import {
  getNotificationEmitterUrl,
  getNotificationEmitterSusbscribeUrl
} from "gsi-ui-components";
import userService from "../helpers/userService";
import { WEBSOCKET_TYPES } from "../helpers/consts";
import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_MESSAGE
} from "../helpers/types";

export const websocketDisconnect = () => async dispatch => {
  if (window.stompClient !== null) {
    try {
      dispatch({
        type: WEBSOCKET_DISCONNECT.INIT
      });
      window.stompClient.deactivate();
      dispatch({
        type: WEBSOCKET_DISCONNECT.SUCCESS
      });
    } catch (error) {
      dispatch({
        type: WEBSOCKET_DISCONNECT.ERROR
      });
    }
  }
};

export const websocketConnect = () => async dispatch => {
  if (userService.isLoggedIn()) {
    const token = userService.getToken();
    const userId = userService.getUserId();
    try {
      dispatch({
        type: WEBSOCKET_CONNECT.INIT
      });
      window.stompClient = new Client({
        webSocketFactory: () => {
          return new SockJS(getNotificationEmitterUrl());
        },
        reconnectDelay: 5000,
        connectHeaders: {
          userId: userId,
          Authorization: "Bearer ".concat(token)
        },
        _disconnectHeaders: { userId: userId },
        onConnect: () => {
          try {
            const url = window.stompClient.webSocket._transport.url.split("/");
            const sessionId = url[url.length - 2];
            window.stompClient.subscribe(
              `${getNotificationEmitterSusbscribeUrl()}-${sessionId}`,
              message => {
                const parsedBody = JSON.parse(message.body);
                if (
                  parsedBody.type === WEBSOCKET_TYPES.SURGERY_ADJACENT ||
                  parsedBody.type === WEBSOCKET_TYPES.SURGERY_CORE
                ) {
                  dispatch({
                    type: WEBSOCKET_MESSAGE.UPDATE,
                    payload: parsedBody.payload
                  });
                }
              }
            );
          } catch (error) {
            console.log(error);
          }
        }
      });
      window.stompClient.activate();
      dispatch({
        type: WEBSOCKET_CONNECT.SUCCESS
      });
    } catch (error) {
      dispatch({
        type: WEBSOCKET_CONNECT.ERROR
      });
    }
  }
};

export const websocketClearMessage = () => async dispatch => {
  dispatch({
    type: WEBSOCKET_MESSAGE.CLEAR
  });
};
