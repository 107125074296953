import { combineReducers } from "redux";
import sidebarReducer from "./sidebar";
import caseListReducer from "./caseList";
import casesReducer from "./cases";
import planningsReducer from "./plannings";
import ticketsReducer from "./tickets";
import shareCaseReducer from "./shareCase";
import acceptCaseReducer from "./acceptCase";
import sharedCaseInfoReducer from "./sharedCaseInfo";
import websocketReducer from "./websocket";
import featuresReducer from "./features";

export default combineReducers({
  sidebar: sidebarReducer,
  caseList: caseListReducer,
  cases: casesReducer,
  plannings: planningsReducer,
  tickets: ticketsReducer,
  shareCase: shareCaseReducer,
  acceptCase: acceptCaseReducer,
  sharedCaseInfo: sharedCaseInfoReducer,
  websocket: websocketReducer,
  features: featuresReducer
});
