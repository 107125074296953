import { TRIGGER_SHARE_CASE } from "../helpers/types";
import { requestStatus } from "gsi-ui-components";

const initialState = {
  triggerStatus: null,
  errorMessage: null,
  messageRef: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_SHARE_CASE.INIT:
      return {
        ...state,
        triggerStatus: requestStatus.LOADING,
        errorMessage: initialState.errorMessage,
        messageRef: initialState.messageRef
      };
    case TRIGGER_SHARE_CASE.SUCCESS:
      return {
        ...state,
        triggerStatus: requestStatus.SUCCESS,
        messageRef: action.payload,
        errorMessage: initialState.errorMessage
      };
    case TRIGGER_SHARE_CASE.ERROR:
      return {
        ...state,
        triggerStatus: requestStatus.ERROR,
        errorMessage: action.payload
      };
    case TRIGGER_SHARE_CASE.UPDATE_REF:
      return {
        ...state,
        messageRef: action.payload
      };
    case TRIGGER_SHARE_CASE.CLEAR:
      return {
        ...state,
        messageRef: initialState.messageRef
      };
    default:
      return state;
  }
};
