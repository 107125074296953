import React from "react";
import PropTypes from "prop-types";
import { requestStatus, BaseImage } from "gsi-ui-components";
import { ReactComponent as LaunchLogo } from "../../../resources/newcase/ctscan-1.0.svg";

const CtScansCard = ({ ctScansStatus, ctScansPreview }) => {
  if (ctScansStatus === requestStatus.SUCCESS) {
    return (
      <div className="ctscan-layout">
        <div className="scan axial-view">
          <BaseImage source={ctScansPreview.axialPreview} propAlt="axialView" />
        </div>
        <div className="scan">
          <BaseImage
            source={ctScansPreview.coronalPreview}
            propAlt="coronalView"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="ctscan-layout">
        <div className="card-content">
          <LaunchLogo className="launch-logo" />
          <div className="launch-recon-label">
            {<div className="scans-text">2D Scans cannot be displayed</div>}
          </div>
        </div>
      </div>
    );
  }
};

CtScansCard.propTypes = {
  ctScansPreview: PropTypes.object
};

export default CtScansCard;
