import React from "react";
import PropTypes from "prop-types";
import {
  InfoCard,
  CASE_STATUS,
  surgeryMeasurementsState,
  caseStatusInfo,
  requestStatus
} from "gsi-ui-components";
import Icon from "@ant-design/icons";
import AlertComponent from "../../common/AlertComponent";
import {
  SupportRequestedIcon,
  ContactSupportIcon,
  InProcessIcon,
  InReviewIcon,
  ReadyIcon,
  RejectedIcon
} from "../../../resources/newcase/status-icons/newCaseIconsIndex";

const addRequestSupportLinkToStatus = description => {
  const descriptionArray = description.split("create a support request");

  return (
    <div>
      {descriptionArray[0]}
      create a support request.
    </div>
  );
};

const addLinkToStatusDescription = (description, ticketJiraId) => {
  const descriptionArray = description.split("JIRA_TICKET_ID");

  return (
    <div>
      {descriptionArray[0]}
      {ticketJiraId}
      {descriptionArray[1]}
    </div>
  );
};

const getCaseStatusDescription = (
  localStatus,
  ticketJiraInfo,
  supportFileAvailable,
  measurementsState,
  caseTickets,
  addRequestSupportLinkToStatusFunction,
  addLinkToStatusDescriptionFunction
) => {
  let statusDescription = caseStatusInfo[localStatus]?.description;

  switch (localStatus) {
    case CASE_STATUS.READY:
      if (
        supportFileAvailable &&
        ![
          surgeryMeasurementsState.ACCEPTED,
          surgeryMeasurementsState.CORRECTED
        ].includes(measurementsState)
      ) {
        statusDescription = statusDescription.support;
      } else {
        statusDescription = statusDescription.normal;
      }
      break;
    case CASE_STATUS.CONTACT_SUPPORT:
      if (caseTickets?.status !== requestStatus.SUCCESS) {
        return;
      } else {
        if (!ticketJiraInfo.ticketId) {
          statusDescription = statusDescription.normal;
          statusDescription =
            addRequestSupportLinkToStatusFunction(statusDescription);
        } else {
          statusDescription = statusDescription.info_needed;
          statusDescription = addLinkToStatusDescriptionFunction(
            statusDescription,
            ticketJiraInfo.ticketId
          );
        }
      }
      break;
    case CASE_STATUS.SUPPORT_REQUESTED:
    case CASE_STATUS.IN_REVIEW:
    case CASE_STATUS.REJECTED:
      if (ticketJiraInfo.ticketId) {
        statusDescription = addLinkToStatusDescriptionFunction(
          statusDescription,
          ticketJiraInfo.ticketId
        );
      } else {
        return;
      }
  }
  return statusDescription;
};

const getStatusIcon = status => {
  const iconComponent = {
    [CASE_STATUS.READY]: ReadyIcon,
    [CASE_STATUS.SUPPORT_REQUESTED]: SupportRequestedIcon,
    [CASE_STATUS.CONTACT_SUPPORT]: ContactSupportIcon,
    [CASE_STATUS.IN_PROCESS]: InProcessIcon,
    [CASE_STATUS.ARCHIVED]: InProcessIcon,
    [CASE_STATUS.IN_REVIEW]: InReviewIcon,
    [CASE_STATUS.REJECTED]: RejectedIcon
  };

  return <Icon component={iconComponent[status]} />;
};

const CaseStatusCard = ({
  caseStatus,
  ticketJiraInfo,
  supportFileAvailable,
  measurementsState,
  caseTickets
}) => {
  const localStatus = caseStatus;
  const statusDescription = getCaseStatusDescription(
    localStatus,
    ticketJiraInfo,
    supportFileAvailable,
    measurementsState,
    caseTickets,
    addRequestSupportLinkToStatus,
    addLinkToStatusDescription
  );

  const formatedStatus = (localStatus && localStatus.replace(/_/g, " ")) || "-";

  return (
    <div className={`case-status-info ${localStatus}`}>
      <InfoCard>
        <div>
          <div className={"status-title"}>{formatedStatus}</div>
          <AlertComponent
            config={{
              message: statusDescription,
              showIcon: true,
              icon: getStatusIcon(localStatus),
              closable: false
            }}
          />
        </div>
      </InfoCard>
    </div>
  );
};

CaseStatusCard.propTypes = {
  caseStatus: PropTypes.string,
  ticketJiraInfo: PropTypes.object,
  supportFileAvailable: PropTypes.bool,
  measurementsState: PropTypes.string,
  caseTickets: PropTypes.object
};

export default CaseStatusCard;
