import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Button, Menu, Space } from "antd";
import { CheckCircleFilled, DownOutlined } from "@ant-design/icons";
import { parseDateFormat, requestStatus } from "gsi-ui-components";
import { getNewFeatures } from "../../actions/features";
import "./NewFeaturesList.less";

const getMenuItems = featuresInfo => {
  return (
    <Menu>
      {featuresInfo.changeLog.map(feature => (
        <Menu.Item key={feature}>
          <div className="collapse-item" key={feature}>
            <CheckCircleFilled style={{ color: "green" }} />
            <div>{feature}</div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

const NewFeaturesList = ({ getNewFeatures, featuresInfo, featuresStatus }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getNewFeatures();
  }, [getNewFeatures]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const showNewFeatures =
    featuresStatus === requestStatus.SUCCESS && !isEmpty(featuresInfo);

  const menu = showNewFeatures && getMenuItems(featuresInfo);

  return (
    showNewFeatures && (
      <div className="new-features-container">
        <p className="latest-version">
          Latest version {parseDateFormat(featuresInfo.releaseDate)} (
          {featuresInfo.version})
        </p>
        <Button
          className="features-button"
          ref={dropdownRef}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Space>
            {`See what is improved in version ${featuresInfo.version}`}
            <DownOutlined className={isOpen ? "rotate-icon" : ""} />
          </Space>
          <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>{menu}</div>
        </Button>
      </div>
    )
  );
};

NewFeaturesList.propTypes = {
  getNewFeatures: PropTypes.func,
  featuresInfo: PropTypes.object,
  featuresStatus: PropTypes.string
};

const mapStateToProps = state => {
  return {
    featuresInfo: state.features?.featuresInfo,
    featuresStatus: state.features?.status
  };
};

export default React.memo(
  connect(mapStateToProps, { getNewFeatures })(NewFeaturesList)
);
