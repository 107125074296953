import { isEmpty } from "lodash";
import {
  PORTAL_LOCATION_PATH,
  FILE_UPLOAD_STATUS,
  CASE_STATUS
} from "gsi-ui-components";
import { SHARE_CASE_TOOLTIP, SHARE_CASE_MODAL } from "./consts";
import { SHARE_CASE_STATUS } from "./types";

export const getCaseSearchPath = caseNumber => {
  return `${PORTAL_LOCATION_PATH.SURGERY_CASES}/${caseNumber}`;
};

export const checkIsLastFileDownloaded = (caseNumber, surgeryCaseFiles) => {
  if (!checkHasUploadedFiles(surgeryCaseFiles)) {
    return false;
  }
  const fileId = getFilesReferencesId(surgeryCaseFiles),
    storagedCaseInfo = JSON.parse(localStorage.getItem(caseNumber));

  if (isEmpty(storagedCaseInfo)) {
    return false;
  } else {
    if (storagedCaseInfo.fileId) {
      return fileId === storagedCaseInfo.fileId;
    } else {
      const lastUpdatedFile = surgeryCaseFiles.references[0];
      if (lastUpdatedFile.state === FILE_UPLOAD_STATUS.UPLOADED_APP) {
        addFileIdToStorage(caseNumber, fileId);
        return true;
      }
      return false;
    }
  }
};

export const checkHasUploadedFiles = surgeryCaseFiles => {
  return (
    surgeryCaseFiles &&
    surgeryCaseFiles.references &&
    surgeryCaseFiles.references.length > 0
  );
};

export const addFileIdToStorage = (caseNumber, fileId) => {
  const currentValue = getCaseInfoFromStorage(caseNumber);
  localStorage.setItem(caseNumber, JSON.stringify({ ...currentValue, fileId }));
};

export const getCaseInfoFromStorage = caseNumber => {
  return JSON.parse(localStorage.getItem(caseNumber));
};

export const getFilesReferencesId = surgeryCaseFiles => {
  const references = surgeryCaseFiles.references;
  return references[0].id;
};

export const checkIsLastFileManualUpload = surgeryCaseFiles => {
  if (!checkHasUploadedFiles(surgeryCaseFiles)) {
    return false;
  }
  const fileState = getFilesReferencesState(surgeryCaseFiles);

  return fileState === FILE_UPLOAD_STATUS.UPLOADED_MANUAL;
};

export const getFilesReferencesState = surgeryCaseFiles => {
  const references = surgeryCaseFiles.references;
  return references[0].state;
};

export const loadCTScansPreview = (
  surgeryCaseObject,
  surgeryCaseInfoObject,
  getCTScanPreviewFunction,
  setCTScanPreviewFunction,
  setCTScanPreviewErrorFunction
) => {
  let ctScanIds, ctScanPlainText;

  if (surgeryCaseObject.ctScan && surgeryCaseObject.ctScan.ctScansPreview) {
    ctScanPlainText = {
      axialPlainText: surgeryCaseObject.ctScan.ctScansPreview.axialPreview,
      coronalPlainText: surgeryCaseObject.ctScan.ctScansPreview.coronalPreview
    };
  } else if (
    surgeryCaseInfoObject.ctScans &&
    surgeryCaseInfoObject.ctScans.references
  ) {
    ctScanIds = {
      firstId:
        surgeryCaseInfoObject.ctScans &&
        surgeryCaseInfoObject.ctScans.references[0] &&
        surgeryCaseInfoObject.ctScans.references[0].id,
      secondId:
        surgeryCaseInfoObject.ctScans &&
        surgeryCaseInfoObject.ctScans.references[1] &&
        surgeryCaseInfoObject.ctScans.references[1].id
    };
  } else {
    ctScanPlainText = {
      axialPlainText:
        surgeryCaseInfoObject.ctScans &&
        surgeryCaseInfoObject.ctScans[0].binaryCTScan,
      coronalPlainText:
        surgeryCaseInfoObject.ctScans &&
        surgeryCaseInfoObject.ctScans[1].binaryCTScan
    };
  }
  if (
    ctScanIds &&
    typeof ctScanIds.firstId === "number" &&
    typeof ctScanIds.secondId === "number"
  ) {
    getCTScanPreviewFunction(ctScanIds);
  } else {
    ctScanPlainText
      ? setCTScanPreviewFunction(ctScanPlainText)
      : setCTScanPreviewErrorFunction();
  }
};

export const selectActiveTab = surgeryPlanning => {
  let activeTab = "0";

  if (!isEmpty(surgeryPlanning)) {
    surgeryPlanning.forEach((planning, index) => {
      const planningData = planning.data;
      const planData = planningData.PlanData;
      const isFavorite = planData.isFavorite;

      if (isFavorite) {
        activeTab = index.toString();
      }
    });
  }

  return activeTab;
};

export const getShareCaseTooltip = surgeryCase => {
  if (surgeryCase.caseStatus !== CASE_STATUS.READY) {
    return SHARE_CASE_TOOLTIP.notReady;
  } else if (!surgeryCase.isSynced) {
    return SHARE_CASE_TOOLTIP.readyNotSynced;
  }
  return SHARE_CASE_TOOLTIP.readyAndSynced;
};

export const validateEmail = emailToCheck => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(emailToCheck);
};

export const getEmailShareTagColor = (validEmailsToShare, tagValue) => {
  return validEmailsToShare.includes(tagValue)
    ? SHARE_CASE_MODAL.TAG_COLOR.DEFAULT
    : SHARE_CASE_MODAL.TAG_COLOR.RED;
};

export const showShareCaseStatus = (message, caseNumber, shareCaseStatus) => {
  message.destroy();
  const showShareCaseStatusValues = {
    [SHARE_CASE_STATUS.DEFAULT]: () => undefined,
    [SHARE_CASE_STATUS.LOADING]: () =>
      message.loading(`Sharing case #${caseNumber}`),
    [SHARE_CASE_STATUS.SUCCESS]: () =>
      message.success(`Case shared #${caseNumber}`),
    [SHARE_CASE_STATUS.ERROR]: () =>
      message.error(`Error sharing case #${caseNumber}. Please, try again.`)
  };
  showShareCaseStatusValues[shareCaseStatus]();
};

export const getCompressedCaseNumber = caseNumber => {
  return `${caseNumber.substring(0, 7)}...${caseNumber.substring(
    caseNumber.length - 4
  )}`;
};
