import React from "react";
import { getKeycloakPortalLoginUrl } from "gsi-ui-components";
import BaseButton from "../common/BaseButton";
import { BUTTON_CONSTS } from "../../helpers/consts";
import downloadImage from "../../resources/layout/downloadImage.png";
import siLogoWhite from "../../resources/layout/si-logo-white.png";
import provoyanceLogoWhite from "../../resources/layout/provoyance-logo-white.png";
import { createAccountUrl } from "../../helpers/URLHelper";
import "./Landing.less";

const Landing = () => {
  return (
    <div className="landing">
      <div className="landing-header">ProVoyance&trade; Shoulder</div>
      <div className="landing-description">
        <img alt="download" src={downloadImage} />
        <div className="description-right-column">
          <p>
            ProVoyance&trade; Shoulder allows surgeons to pre-operatively plan a
            shoulder arthroplasty using available implants from Shoulder
            Innovations. 3D models of the humerus and scapula are generated by
            ProVoyance from patient imaging which allows surgeons to select the
            surgery type, desired implant sizes and locations for a specific
            patient condition.
          </p>
        </div>
      </div>
      <div className="landing-subtitle">
        “Designed by surgeons for surgeons”
      </div>
      <div className="landing-login">
        <BaseButton
          config={{
            type: BUTTON_CONSTS.TYPE.PRIMARY,
            size: BUTTON_CONSTS.SIZE.LARGE,
            className: "login-button"
          }}
          onClick={() => {
            window.location.href = getKeycloakPortalLoginUrl();
          }}
          text={"Login"}
        />
        <div className="login-text">
          Would you like an account?
          <a
            href={createAccountUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="signup-link"
          >
            <BaseButton
              config={{
                type: BUTTON_CONSTS.TYPE.LINK,
                size: BUTTON_CONSTS.SIZE.DEFAULT
              }}
              text={"Click in here"}
            />
          </a>
        </div>
      </div>
      <div className="landing-footer">
        <div className="footer-description-container">
          <div className="footer-description">
            ProVoyance&trade; Shoulder allows surgeons to visualize and adjust
            all the details of a shoulder arthroplasty procedure that utilizes
            the Shoulder Innovations InSet&trade; implant system. Surgeons can
            completely plan a procedure down to the most minute details and have
            a clearly visualized preview of what they will face in the operating
            room.
          </div>

          <div className="logos">
            <img
              alt="provoyance-logo"
              className="provoyance-logo"
              src={provoyanceLogoWhite}
            />
            <img alt="si-logo" className="si-logo" src={siLogoWhite} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Landing);
