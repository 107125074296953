import {
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_MESSAGE
} from "../helpers/types";
import { WEBSOCKET_STATUS } from "../helpers/consts";

const initialState = {
  status: null,
  message: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WEBSOCKET_CONNECT.INIT:
      return { ...initialState };
    case WEBSOCKET_CONNECT.SUCCESS:
      return {
        ...state,
        status: WEBSOCKET_STATUS.CONNECTED
      };
    case WEBSOCKET_CONNECT.ERROR:
    case WEBSOCKET_DISCONNECT.ERROR:
      return { ...state, status: WEBSOCKET_STATUS.ERROR };
    case WEBSOCKET_DISCONNECT.INIT:
      return { ...state };
    case WEBSOCKET_DISCONNECT.SUCCESS:
      return { ...state, status: WEBSOCKET_STATUS.DISCONNECTED };
    case WEBSOCKET_MESSAGE.UPDATE:
      return { ...state, message: action.payload };
    case WEBSOCKET_MESSAGE.CLEAR:
      return { ...state, message: initialState.message };
    default:
      return state;
  }
};
