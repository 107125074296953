import React from "react";
import PropTypes from "prop-types";
import { Card, Divider } from "antd";
import "./Requirements.less";

const getRequirementsList = requirements => {
  return (
    <ul>
      {Object.entries(requirements).map(([specKey, specValue]) => (
        <li key={specKey}>
          {specKey}: {specValue}
        </li>
      ))}
    </ul>
  );
};

const Requirements = ({ requirements }) => {
  return (
    <div className="requirements-container">
      <Card bordered={true}>
        <div>
          <p className="specs-title">
            Minimum hardware and software requirements.
          </p>
          <div className="specs-columns">
            <div className="spec-column">
              <p>For Windows:</p>
              <ul>
                {requirements?.windows &&
                  getRequirementsList(requirements.windows)}
              </ul>
            </div>
            <Divider type="vertical" />
            <div className="spec-column">
              <p>For Mac:</p>
              <ul>
                {requirements?.macos && getRequirementsList(requirements.macos)}
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

Requirements.propTypes = {
  requirements: PropTypes.object
};

export default React.memo(Requirements);
