import React from "react";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Welcome from "./Welcome";
import DownloadsFooter from "./DownloadsFooter";
import "./Home.less";

const Home = () => {
  return (
    <React.Fragment>
      <Header title="Dashboard" />
      <Welcome />
      <DownloadsFooter />
    </React.Fragment>
  );
};

Home.propTypes = {
  triggerShareCase: PropTypes.func
};

export default Home;
