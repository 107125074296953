import React from "react";
import PropTypes from "prop-types";
import "./Header.less";

const Header = ({ title, titleSidedChildren }) => {
  return (
    <div className="header-container">
      <label>
        {title}
        {titleSidedChildren}
      </label>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  titleSidedChildren: PropTypes.any
};

export default React.memo(Header);
