export const getNewFeatures = () => async dispatch => {
  const [{ NEW_FEATURES }, { getFeaturesUrl, sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/URLHelper`)
    ]);

  try {
    dispatch({ type: NEW_FEATURES.LOADING });

    const response = await sendGetRequest(getFeaturesUrl());

    const data = response?.data?.data;
    const newFeaturesInfo = data?.[0]?.attributes;

    dispatch({ type: NEW_FEATURES.SUCCESS, payload: newFeaturesInfo });
  } catch (error) {
    dispatch({ type: NEW_FEATURES.ERROR });
    console.error(`While retrieving latest features: ${error}`);
  }
};
