import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  getKeycloakPortalLoginUrl,
  PORTAL_LOCATION_PATH
} from "gsi-ui-components";
import userService from "../../helpers/userService";
import Home from "../home/Home";
import CaseList from "../caseList/CaseList";
import CaseDetails from "../caseDetails/CaseDetails";
import Landing from "../landing/Landing";
import SiderMenu from "../layout/SiderMenu";
import SharedLanding from "../sharing/SharedLanding";

const Secured = () => {
  const loggedIn = userService.isLoggedIn();

  return loggedIn ? (
    <div className="main-layout">
      <SiderMenu />
      <div className="main-content">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path={PORTAL_LOCATION_PATH.SURGERY_CASES}
            component={CaseList}
          />
          <Route
            exact
            path={`${PORTAL_LOCATION_PATH.SURGERY_CASES}/:caseNumber`}
            component={CaseDetails}
          />
          <Route
            path={`${PORTAL_LOCATION_PATH.CASE_INVITATION}/:invitationUuid`}
            component={SharedLanding}
          />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </div>
    </div>
  ) : (
    <Switch>
      <Route
        path={`${PORTAL_LOCATION_PATH.CASE_INVITATION}/:invitationUuid`}
        component={() => {
          window.location.href = getKeycloakPortalLoginUrl(location.pathname);
        }}
      />
      <Route path="/" component={Landing} />
    </Switch>
  );
};

export default React.memo(Secured);
