import axios from "axios";
import {
  BODY_PARSER,
  getKeycloakLoginPageUrl,
  API_RESOURCE
} from "gsi-ui-components";
import userService from "./userService";

const UPDATE_MANAGER_ENDPOINT = "/updateManager/";
const KEYCLOACK_TOKEN_ENDPOINT = `realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`;
const CASE_TICKET_ENDPOINT = "/services/support/case-ticket";
const PERSISTENCE_ENDPOINT = "/services/entities";
const ENTITIES_ENDPOINT = "/entities";
const APP_ENDPOINT = "/app";
const RELEASES_ENDPOINT = "/releases";
const LATEST_ENDPOINT = "/latest";
const CT_SCAN_ENDPOINT = "/ctscan";
const FIND_BY_CASE_ID = "?caseId=";
const SORTBY_PRIMARY = "&sortBy=id";
const PARAM_SEPARATOR = `&`;
const PARAM_VALUE = `=`;
const RESOURCES_ENDPOINT = "/resources";
const TRAINING_ENDPOINT = "/training";

export const PLATFORM = {
  MAC: "macos",
  WINDOWS: "windows"
};

export const computeEnvironment = (variable, suffix = "") => {
  return process.env[variable] ? `${process.env[variable]}${suffix}` : "";
};

const APPLICATION_NAME = "ProVoyance-Shoulder";
const INSTALLER_ENVIRONMENT = computeEnvironment(
  "REACT_APP_ENVIRONMENT_INSTALLER",
  "-"
);

export const INSTALLER_NAME = {
  [PLATFORM.MAC]: `${APPLICATION_NAME}-${INSTALLER_ENVIRONMENT}${process.env.REACT_APP_DOWNLOAD_VERSION}.pkg`,
  [PLATFORM.WINDOWS]: `${APPLICATION_NAME}-Installer-${INSTALLER_ENVIRONMENT}${process.env.REACT_APP_DOWNLOAD_VERSION}.exe`
};

export const FORM_ENCODED_CONTENT_TYPE = "application/x-www-form-urlencoded";

export const getInstallerUrl = platform => {
  return `${process.env.REACT_APP_SURGERY_ADJACENT_URL}${UPDATE_MANAGER_ENDPOINT}${platform}/${INSTALLER_NAME[platform]}`;
};

export const getDownloadUrl = endpoint => {
  return `${process.env.REACT_APP_SURGERY_ADJACENT_URL}${RESOURCES_ENDPOINT}${TRAINING_ENDPOINT}/${endpoint}`;
};

export const getAuthUrl = () => {
  return `${process.env.REACT_APP_KEYCLOAK_URL}${KEYCLOACK_TOKEN_ENDPOINT}`;
};

export const getCaseTicketsUrl = caseId => {
  return `${process.env.REACT_APP_SURGERY_CORE_URL}${CASE_TICKET_ENDPOINT}${FIND_BY_CASE_ID}${caseId}${SORTBY_PRIMARY}`;
};

export const getCTScanById = id => {
  const scanUrl = `${process.env.REACT_APP_SURGERY_CORE_URL}${PERSISTENCE_ENDPOINT}${CT_SCAN_ENDPOINT}`;
  if (id) {
    return scanUrl.concat(`/${id}`);
  }
  return scanUrl;
};

export const getFeaturesUrl = appName => {
  const APP_NAME = appName || "provoyance-shoulder";

  return `${process.env.REACT_APP_SURGERY_ADJACENT_URL}${ENTITIES_ENDPOINT}${APP_ENDPOINT}/${APP_NAME}${RELEASES_ENDPOINT}${LATEST_ENDPOINT}`;
};

export const gsiWebsiteUrl = "https://genesissoftwareinnovations.com/";

export const psWalkthroughVideoUrl =
  "https://www.youtube.com/watch?v=Xcf47xARKB0";

export const createAccountUrl =
  "https://shoulderinnovations.com/surgeon-inquiry/";

export const portalSentryURL =
  "https://bdbd91ba1b9348449222baebb7a9c49f@apm.genesisplanningsoftware.com/17";

export const refreshToken = async () => {
  if (userService.isTokenExpired()) {
    try {
      await userService.refreshToken();
    } catch {
      window.location.href = getKeycloakLoginPageUrl(window.location.href);
    }
  }
};

export const paramFactory = params => {
  let paramStrings = params.map(param => {
    return `${param.param}${PARAM_VALUE}${param.value}`;
  });
  return paramStrings.join(PARAM_SEPARATOR);
};

export const getDefaultParams = (startIndex, pageSize) => {
  return [
    {
      param: API_RESOURCE.PARAM_START_INDEX,
      value: startIndex
    },
    {
      param: API_RESOURCE.PARAM_PAGE_SIZE,
      value: pageSize
    }
  ];
};

export const sendGetRequest = async (
  url,
  body = null,
  skipCache = false,
  additionalHeaders = {}
) => {
  await refreshToken();
  const baseHeaders = {
    Authorization: "Bearer ".concat(userService.getToken()),
    "Preview-App": "application/preview-web"
  };

  if (skipCache) {
    additionalHeaders["Cache-Control"] = "no-cache";
  }

  const headers = { ...baseHeaders, ...additionalHeaders };

  if (body) {
    return axios.get(url, body, {
      headers: headers
    });
  } else {
    return axios.get(url, {
      headers: headers
    });
  }
};

export const sendPostRequest = async (url, bodyParser, body, contentType) => {
  await refreshToken();

  const token = userService.getToken();

  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.post(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.post(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.post(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendPutRequest = async url => {
  await refreshToken();
  return axios.put(url, null, {
    headers: {
      Authorization: "Bearer ".concat(userService.getToken())
    }
  });
};

export const getFile = async (url, target = "_parent") => {
  try {
    let response = await sendGetRequest(url);
    window.open(response.data.url, target);
  } catch (error) {
    console.log(error);
  }
};
