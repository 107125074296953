import { SHARE_CASE_STATUS } from "../helpers/types";

const initialState = {
  status: SHARE_CASE_STATUS.DEFAULT
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHARE_CASE_STATUS.LOADING:
      return { ...state, status: SHARE_CASE_STATUS.LOADING };
    case SHARE_CASE_STATUS.SUCCESS:
      return { ...state, status: SHARE_CASE_STATUS.SUCCESS };
    case SHARE_CASE_STATUS.ERROR:
      return { ...state, status: SHARE_CASE_STATUS.ERROR };
    case SHARE_CASE_STATUS.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
