import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { connect } from "react-redux";
import {
  CheckCircleFilled,
  LoadingOutlined,
  CloseCircleFilled
} from "@ant-design/icons";
import { Spin } from "antd";
import {
  InfoCard,
  PORTAL_LOCATION_PATH,
  requestStatus
} from "gsi-ui-components";
import Header from "../layout/Header";
import { sharedFeatures, DEFAULT_ERROR_MESSAGE } from "../../helpers/consts";
import { triggerShareCase } from "../../actions/acceptCase";
import "./SharedLanding.less";

const renderErrorScreen = message => {
  const title =
    message === DEFAULT_ERROR_MESSAGE
      ? "Something went wrong"
      : "Could not accept invitation";
  const displayMessage = message.includes("Current status is")
    ? "Share case invitation was already accepted"
    : message;

  return (
    <div>
      <div className="error-screen">
        <InfoCard title={title}>
          <div className="card-body-container">
            <CloseCircleFilled />
            {displayMessage}
          </div>
        </InfoCard>
      </div>
    </div>
  );
};

const renderLandingScreen = (acceptCaseInfo, sharedCaseNumber) => {
  return (
    <div>
      {acceptCaseInfo.triggerStatus === requestStatus.SUCCESS ? (
        getSuccessCloneScreen(sharedCaseNumber)
      ) : (
        <Spin className={"spinner"} indicator={<LoadingOutlined />} />
      )}
    </div>
  );
};

const getSuccessCloneScreen = sharedCaseNumber => {
  return (
    <div className="landing">
      <div className="landing-title">
        {sharedCaseNumber ? (
          <div>
            {`Case #${sharedCaseNumber} is now available in your `}
            <Link to={PORTAL_LOCATION_PATH.SURGERY_CASES}>case list</Link>.
          </div>
        ) : (
          `In a few minutes a copy of this case will be available in your case
  list and we will provide you the new Case Number.`
        )}
      </div>
      <div className="shared-features">
        {sharedCaseNumber ? `You are able to:` : `You will be able to:`}
        {sharedFeatures.map(item => (
          <li key={item} className="shared-features-item">
            <CheckCircleFilled className="check-icon" />
            <div>{item}</div>
          </li>
        ))}
      </div>
    </div>
  );
};

const SharedLanding = ({
  triggerShareCase,
  websocketUpdate,
  acceptCaseInfo
}) => {
  const params = useParams();
  const [sharedCaseNumber, setSharedCaseNumber] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const caseAccepted = useRef(false);

  useEffect(() => {
    if (params.invitationUuid) {
      !caseAccepted.current && triggerShareCase(params.invitationUuid);
      caseAccepted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (websocketUpdate.message.caseNumber) {
      setSharedCaseNumber(websocketUpdate.message.caseNumber);
    }
  }, [websocketUpdate]);

  useEffect(() => {
    if (acceptCaseInfo.errorMessage) {
      setErrorMessage(acceptCaseInfo.errorMessage);
    }
  }, [acceptCaseInfo]);

  return (
    <div>
      <Header title="Shared case" />
      {errorMessage
        ? renderErrorScreen(errorMessage)
        : renderLandingScreen(acceptCaseInfo, sharedCaseNumber)}
    </div>
  );
};

SharedLanding.propTypes = {
  triggerShareCase: PropTypes.func,
  websocketUpdate: PropTypes.object,
  acceptCaseInfo: PropTypes.object
};

const mapStateToProps = state => {
  return {
    websocketUpdate: state.websocket,
    acceptCaseInfo: state.acceptCase
  };
};

export default connect(mapStateToProps, {
  triggerShareCase
})(SharedLanding);
