import React from "react";
import downloadImage from "../../resources/layout/downloadImage.png";
import AdditionalMaterial from "./AdditionalMaterial";
import "./DownloadsFooter.less";

const DownloadsFooter = () => {
  return (
    <div className="downloads-footer">
      <img alt="download" src={downloadImage} />
      <AdditionalMaterial />
    </div>
  );
};

export default React.memo(DownloadsFooter);
