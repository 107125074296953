import { REQUEST_STATUS } from "gsi-ui-components";
import { CASE_LIST, CASE_COUNT } from "../helpers/types";

const initialState = {
  status: null,
  responseData: {},
  caseList: [],
  sharedCases: [],
  totalCases: null,
  caseCountStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASE_LIST.LOADING:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING
      };
    case CASE_LIST.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        responseData: action.payload.responseData,
        caseList: action.payload.responseData.entities,
        sharedCases: [...state.sharedCases, ...action.payload.sharedCases]
      };
    case CASE_LIST.ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.ERROR
      };
    case CASE_LIST.CLEAR:
      return { ...initialState };
    case CASE_COUNT.LOADING:
      return {
        ...state,
        caseCountStatus: REQUEST_STATUS.LOADING
      };
    case CASE_COUNT.SUCCESS:
      return {
        ...state,
        caseCountStatus: REQUEST_STATUS.SUCCESS,
        totalCases: action.payload
      };
    case CASE_COUNT.ERROR:
      return {
        ...state,
        caseCountStatus: REQUEST_STATUS.ERROR
      };
    default:
      return state;
  }
};
