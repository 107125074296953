import { TICKET_LIST } from "../helpers/types";
import { requestStatus } from "gsi-ui-components";

const initialState = {
  status: null,
  responseData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TICKET_LIST.LOADING:
      return { ...state, status: requestStatus.LOADING };
    case TICKET_LIST.SUCCESS:
      return {
        ...state,
        status: requestStatus.SUCCESS,
        responseData: action.payload
      };
    case TICKET_LIST.ERROR:
      return { ...state, status: requestStatus.ERROR };
    case TICKET_LIST.CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
