import moment from "moment";
import { isEmpty } from "lodash";
import {
  FIRST_PAGE,
  EXPAND_PARAM,
  SHORT_DATE_FORMAT,
  DATE_FORMAT,
  ASCII_ENCODE,
  API_RESOURCE,
  SURGERY_CASE_ATTR,
  caseFilterType,
  getCaseByParametersUrl
} from "gsi-ui-components";
import { paramFactory, getDefaultParams } from "../../helpers/URLHelper";

export const filterCases = (
  filters,
  activeFilters,
  pageSize,
  sorter,
  functions
) => {
  let filter;
  let requestParams = [];
  const { getCaseList, setFiltersCleared, setCurrentPage, getTotalCases } =
    functions;

  for (filter in filters) {
    let selectedFilter = filters[filter];
    if (!isEmpty(selectedFilter)) {
      let filterBy = filterFields[filter];

      switch (filter) {
        case SURGERY_CASE_ATTR.CASE_STATUS:
        case SURGERY_CASE_ATTR.SURGERY_STATUS:
          selectedFilter = getCaseStatusFilter(selectedFilter);
          break;
        case SURGERY_CASE_ATTR.SURGERY_DATE:
          selectedFilter = getSurgeryDateFilter(selectedFilter);
          break;
        case SURGERY_CASE_ATTR.LAST_MODIFIED:
          selectedFilter = getLastModifiedDateFilter(selectedFilter);
          break;
        case SURGERY_CASE_ATTR.PATIENT_NAME:
        case SURGERY_CASE_ATTR.CLASSIFICATION:
        case SURGERY_CASE_ATTR.CASE_NUMBER:
          ({ selectedFilter, filterBy } = getTextFilter(
            selectedFilter,
            filter
          ));
          break;
        default:
          break;
      }

      requestParams.push({
        param: filterBy,
        value: selectedFilter
      });
    }
  }

  setFiltersCleared(isEmpty(filters));

  activeFilters.current = requestParams;

  sorter.order && requestParams.push(getSortQuery(sorter));

  getCaseList(
    getCaseByParametersUrl([...getDefaultParams(0, pageSize), ...requestParams])
  );

  getTotalCases(
    `?${EXPAND_PARAM}&${paramFactory([
      ...getDefaultParams(0, pageSize),
      ...requestParams
    ])}`
  );

  setCurrentPage(FIRST_PAGE);
};

const getTextFilter = (selectedFilter, filter) => {
  return {
    selectedFilter: `${ASCII_ENCODE.PERCENT}${encodeURIComponent(
      selectedFilter
    )}${ASCII_ENCODE.PERCENT}`,
    filterBy: caseFilterType[filter]
  };
};

const getCaseStatusFilter = selectedFilter => {
  return selectedFilter.join(ASCII_ENCODE.PIPE);
};

const getSurgeryDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");

  const firstDate = moment(filteredDates[0], SHORT_DATE_FORMAT).format(
    DATE_FORMAT.bigEndian
  );
  const secondDate = moment(filteredDates[1], SHORT_DATE_FORMAT).format(
    DATE_FORMAT.bigEndian
  );
  return `${firstDate}~${secondDate}`;
};

const getLastModifiedDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = moment(filteredDates[0])
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDThh:mm:ss");
  const secondDate = moment(filteredDates[1])
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDThh:mm:ss");

  return `${firstDate}Z~${secondDate}Z`;
};

export const getNextRequestQuery = (
  page,
  pageSize,
  activeFilters,
  sortedInfo
) => {
  const startIndex = pageSize * (page - 1);

  const params = [...activeFilters, ...getDefaultParams(startIndex, pageSize)];

  sortedInfo.order && params.push(getSortQuery(sortedInfo));

  return getCaseByParametersUrl(params);
};

const getSortQuery = sorter => {
  const sortOrder = {
    ascend: API_RESOURCE.PARAM_SORT_BY_ORDER_ASC,
    descend: API_RESOURCE.PARAM_SORT_BY_ORDER_DESC
  };

  return {
    param: API_RESOURCE.PARAM_SORT_BY,
    value: `${filterFields[sorter.columnKey]}:${sortOrder[sorter.order]}`
  };
};

const filterFields = {
  [SURGERY_CASE_ATTR.SURGERY_STATUS]: "state",
  [SURGERY_CASE_ATTR.SURGERY_DATE]: "date",
  [SURGERY_CASE_ATTR.PATIENT_NAME]: "patient.fullName",
  [SURGERY_CASE_ATTR.CLASSIFICATION]: "classification",
  [SURGERY_CASE_ATTR.CASE_NUMBER]: "caseNumber",
  [SURGERY_CASE_ATTR.LAST_MODIFIED]: "lastModifiedPlanningDate",
  [SURGERY_CASE_ATTR.CASE_STATUS]: "status"
};
