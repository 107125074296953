import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { message, Select, Tooltip, Tag } from "antd";
import { isEmpty } from "lodash";
import { InfoCircleFilled } from "@ant-design/icons";
import { BaseModal, InputCell, inputType } from "gsi-ui-components";
import AlertComponent, { alertTypes } from "../common/AlertComponent";
import { BUTTON_CONSTS, SHARE_CASE_MODAL } from "../../helpers/consts";
import {
  getEmailShareTagColor,
  validateEmail,
  showShareCaseStatus
} from "../../helpers/caseHelper";
import { shareCase } from "../../actions/shareCase";
import "./ShareCaseModal.less";

const getFieldDecorator = messageValue => {
  return <label className="required-information">{messageValue}</label>;
};

const ShareCaseModal = ({
  caseNumber,
  caseId,
  isModalVisible,
  setModalVisible,
  shareCase,
  shareCaseStatus
}) => {
  const [emailsToShare, setEmailsToShare] = useState([]);
  const [validEmailsToShare, setValidEmailsToShare] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [agreeToShareInfo, setAgreeToShareInfo] = useState(false);
  const showEmailFormatError = useRef(false);
  const showRequiredError = useRef(false);

  useEffect(() => {
    showShareCaseStatus(message, caseNumber, shareCaseStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareCaseStatus]);

  const handleEmailsChange = emailValue => {
    showRequiredError.current = emailValue.length === 0;
    const validEmails = emailValue.filter(validateEmail);
    showEmailFormatError.current = validEmails.length !== emailValue.length;
    setValidEmailsToShare(validEmails);
    setEmailsToShare(emailValue);
  };

  const tagRender = tagRenderProps => {
    const { label, value, closable, onClose } = tagRenderProps;

    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    const tagColor = getEmailShareTagColor(validEmailsToShare, value);

    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const handleModalShare = async () => {
    handleModalClose();
    await shareCase(caseId, caseNumber, commentValue, validEmailsToShare);
  };

  const emailShareFieldDecorator = () => {
    let emailFieldMessage = "";

    if (showRequiredError.current) {
      emailFieldMessage = SHARE_CASE_MODAL.EMAIL_ERROR.REQUIRED;
    } else if (showEmailFormatError.current) {
      emailFieldMessage = SHARE_CASE_MODAL.EMAIL_ERROR.FORMAT;
    }

    return emailFieldMessage;
  };

  const handleModalClose = () => {
    setEmailsToShare([]);
    setValidEmailsToShare([]);
    setAgreeToShareInfo(false);
    setCommentValue("");
    showRequiredError.current = false;
    showEmailFormatError.current = false;
    setModalVisible(false);
  };

  return (
    <BaseModal
      handleCancel={handleModalClose}
      config={{
        visible: isModalVisible,
        title: `Share case. #${caseNumber}`,
        className: "share-case-modal",
        footer: [
          {
            config: {
              type: BUTTON_CONSTS.TYPE.DEFAULT,
              size: BUTTON_CONSTS.SIZE.DEFAULT
            },
            onClick: handleModalClose,
            text: "Cancel"
          },
          {
            config: {
              type: BUTTON_CONSTS.TYPE.PRIMARY,
              size: BUTTON_CONSTS.SIZE.DEFAULT,
              disabled:
                !agreeToShareInfo ||
                isEmpty(emailsToShare) ||
                showEmailFormatError.current
            },
            onClick: async () => {
              await handleModalShare();
            },
            text: "Share"
          }
        ]
      }}
    >
      <React.Fragment>
        <div className="email-input-title">
          <p className="title">
            Enter email to share this case.{getFieldDecorator("*")}
          </p>
          <Tooltip title={SHARE_CASE_MODAL.PS_EMAIL_TOOLTIP} placement="right">
            <InfoCircleFilled />
          </Tooltip>
        </div>
        <Select
          open={false}
          mode="tags"
          style={{
            width: "100%"
          }}
          onChange={handleEmailsChange}
          tokenSeparators={[","]}
          className={"share-case-emails"}
          autoFocus={true}
          value={emailsToShare}
          placeholder={SHARE_CASE_MODAL.SELECT_PLACEHOLDER}
          tagRender={tagRender}
          maxTagTextLength={35}
        />
        <label className="required-information">
          {emailShareFieldDecorator()}
        </label>
        <p className="title">Do you want to add any comments?</p>
        <InputCell
          type={inputType.TEXTAREA}
          config={{
            minRows: 3,
            maxRows: 6,
            maxlength: 255,
            placeholder: "Comments will be share with the recipients only.",
            value: commentValue
          }}
          onChange={e => {
            setCommentValue(e.target.value);
          }}
        />
        <React.Fragment>
          <div className="alert">
            <AlertComponent
              config={{
                className: "alert-component",
                type: alertTypes.INFO,
                message: SHARE_CASE_MODAL.ALERT_DESCRIPTION,
                showIcon: true,
                closable: false,
                icon: <InfoCircleFilled />
              }}
            />
          </div>
          <InputCell
            className="agree-info-checkbox"
            type={inputType.CHECKBOX}
            config={{
              label: (
                <p>
                  {SHARE_CASE_MODAL.SHARE_CASE_INFO}
                  {getFieldDecorator("*")}
                </p>
              ),
              checked: agreeToShareInfo
            }}
            onChange={() => setAgreeToShareInfo(!agreeToShareInfo)}
          />
          {getFieldDecorator("*Required.")}
        </React.Fragment>
      </React.Fragment>
    </BaseModal>
  );
};

ShareCaseModal.propTypes = {
  caseNumber: PropTypes.string,
  caseId: PropTypes.number,
  shareCaseStatus: PropTypes.string,
  isModalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  shareCase: PropTypes.func
};

const mapStateToProps = state => {
  return { shareCaseStatus: state.shareCase.status };
};

export default connect(mapStateToProps, {
  shareCase
})(ShareCaseModal);
