import { SHARED_CASE_INFO, SHARED_BY_INFO } from "../helpers/types";
import { requestStatus } from "gsi-ui-components";

const initialState = {
  status: null,
  attributes: null,
  sharedByStatus: null,
  sharedByAttributes: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHARED_CASE_INFO.LOADING:
      return { ...initialState, status: requestStatus.LOADING };
    case SHARED_CASE_INFO.SUCCESS:
      return {
        ...state,
        status: requestStatus.SUCCESS,
        attributes: action.payload
      };
    case SHARED_CASE_INFO.ERROR:
      return { ...initialState, status: requestStatus.ERROR };
    case SHARED_CASE_INFO.CLEAR:
      return { ...initialState };
    case SHARED_BY_INFO.LOADING:
      return { ...initialState, sharedByStatus: requestStatus.LOADING };
    case SHARED_BY_INFO.SUCCESS:
      return {
        ...state,
        sharedByStatus: requestStatus.SUCCESS,
        sharedByAttributes: action.payload
      };
    case SHARED_BY_INFO.ERROR:
      return { ...initialState, sharedByStatus: requestStatus.ERROR };
    default:
      return state;
  }
};
