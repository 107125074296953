import { LOCATION_CHANGED } from "../helpers/types";

const initialState = {
  currentLocation: "/"
};

export default (state = initialState, action) => {
  if (action.type === LOCATION_CHANGED) {
    return { ...state, currentLocation: action.payload };
  } else {
    return state;
  }
};
