import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Secured from "./components/auth/Secured";
import { websocketConnect } from "./actions/websocket";
import CaseCloningAlert from "./components/notifications/CaseCloningAlert";
import "./App.less";

const App = ({ websocketConnect }) => {
  websocketConnect();

  return (
    <BrowserRouter>
      <CaseCloningAlert />
      <Secured />
    </BrowserRouter>
  );
};

App.propTypes = {
  websocketConnect: PropTypes.func
};

export default React.memo(connect(null, { websocketConnect })(App));
