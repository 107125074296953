import { REQUEST_STATUS } from "gsi-ui-components";
import { CASE_INFO, CT_SCAN } from "../helpers/types";

const initialState = {
  status: null,
  data: {},
  ctScan: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASE_INFO.LOADING:
      return { ...initialState, status: REQUEST_STATUS.LOADING };
    case CASE_INFO.SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCESS,
        data: action.payload
      };
    case CASE_INFO.ERROR:
      return { ...initialState, status: REQUEST_STATUS.ERROR };
    case CASE_INFO.CLEAR:
      return { ...initialState };
    case CT_SCAN.RESET:
      return {
        ...state,
        ctScan: null
      };
    case CT_SCAN.LOADING:
      return {
        ...state,
        ctScan: { ctScansPreview: null, ctScanStatus: REQUEST_STATUS.LOADING }
      };
    case CT_SCAN.SUCCESS:
      return {
        ...state,
        ctScan: {
          ctScansPreview: action.payload,
          ctScanStatus: REQUEST_STATUS.SUCCESS
        }
      };
    case CT_SCAN.ERROR:
      return {
        ...state,
        ctScan: {
          ctScansPreview: null,
          ctScanStatus: REQUEST_STATUS.ERROR,
          message: action.payload
        }
      };
    default:
      return state;
  }
};
