import { BODY_PARSER } from "gsi-ui-components";

export const shareCase =
  (caseId, caseNumber, comment, emails) => async dispatch => {
    const [{ SHARE_CASE_STATUS }, { sendPostRequest }, { getShareCaseUrl }] =
      await Promise.all([
        import(`../helpers/types`),
        import(`../helpers/URLHelper`),
        import(`gsi-ui-components`)
      ]);
    try {
      dispatch({ type: SHARE_CASE_STATUS.LOADING });
      await sendPostRequest(
        getShareCaseUrl(),
        BODY_PARSER.JSON,
        {
          caseId: caseId,
          caseNumber: caseNumber,
          comment: comment,
          emails: emails
        },
        "application/json"
      );
      dispatch({ type: SHARE_CASE_STATUS.SUCCESS });
    } catch (error) {
      dispatch({ type: SHARE_CASE_STATUS.ERROR });
    }
  };

export const clearShareCaseStatus = () => async dispatch => {
  const { SHARE_CASE_STATUS } = await import(`../helpers/types`);

  dispatch({
    type: SHARE_CASE_STATUS.CLEAR
  });
};
