export const getCaseTicketList = caseId => async dispatch => {
  const [{ TICKET_LIST }, { getCaseTicketsUrl, sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/URLHelper`)
    ]);

  try {
    dispatch({
      type: TICKET_LIST.LOADING
    });
    const response = await sendGetRequest(getCaseTicketsUrl(caseId));

    dispatch({
      type: TICKET_LIST.SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: TICKET_LIST.ERROR
    });
  }
};

export const clearCaseTicketList = () => async dispatch => {
  const { TICKET_LIST } = await import(`../helpers/types`);

  dispatch({
    type: TICKET_LIST.CLEAR
  });
};
