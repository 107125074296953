import React, { useEffect } from "react";
import {
  FolderOpenOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import gsiLogo from "../../resources/layout/gsi-isotype-white.png";
import userService from "../../helpers/userService";
import { changeLocation } from "../../actions/sidebar";
import { loadImplantsInfo } from "../../actions/cases";
import { websocketDisconnect } from "../../actions/websocket";
import "./SiderMenu.less";

const { Sider } = Layout;
const { SubMenu } = Menu;
const locationMenuItems = {
  "/": "home",
  "/surgery-cases": "surgery-cases"
};

const SiderMenu = ({
  changeLocation,
  locationHash,
  loadImplantsInfo,
  websocketDisconnect
}) => {
  const location = useLocation();

  useEffect(() => {
    loadImplantsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeLocation(location.pathname);
  }, [changeLocation, location]);

  return (
    <Sider collapsedWidth={"2.5rem"} collapsed={true}>
      <Menu
        className="navigation-menu"
        selectedKeys={[locationMenuItems[locationHash]]}
        mode="inline"
        collapsed="true"
        forceSubMenuRender={true}
      >
        <div className="layout-logo-container">
          <img src={gsiLogo} alt="Gsi Logo" />
        </div>
        <Menu.Item key="home" className="layout-selected">
          <Link to="/"></Link>
          <HomeOutlined className="layout-icon" />
          <span>Home</span>
        </Menu.Item>
        <Menu.Item key="surgery-cases" className="layout-selected">
          <Link to="/surgery-cases"></Link>
          <FolderOpenOutlined className="layout-icon" />
          <span>Cases</span>
        </Menu.Item>
        <div className="menu-separator"></div>
        <SubMenu
          key="logout"
          className="layout-selected"
          title={
            <div className="layout-user-option">
              <UserOutlined className="layout-user-icon" />
            </div>
          }
        >
          <Menu.Item
            key="logout"
            className="logout-border-top"
            onClick={() => {
              websocketDisconnect();
              userService.logout();
            }}
          >
            <LogoutOutlined className="layout-user-icon" />
            <span>Logout</span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

SiderMenu.propTypes = {
  changeLocation: PropTypes.func,
  locationHash: PropTypes.string,
  loadImplantsInfo: PropTypes.func,
  websocketDisconnect: PropTypes.func
};

const mapStateToProps = state => {
  return {
    locationHash: state.sidebar.currentLocation
  };
};

export default React.memo(
  connect(mapStateToProps, {
    changeLocation,
    loadImplantsInfo,
    websocketDisconnect
  })(SiderMenu)
);
