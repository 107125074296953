import { requestStatus } from "gsi-ui-components";
import {
  getTicketJiraId,
  getMostRecentOpenTicket
} from "../../helpers/ticketsHelper";

export const updateTicketJiraInfo = (
  caseTickets,
  setTicketJiraInfoFunction
) => {
  if (caseTickets?.status === requestStatus.SUCCESS) {
    const ticketsArray = caseTickets.responseData?.data;
    const mostRecentTicket = getMostRecentOpenTicket(ticketsArray);
    const mostRecentTicketId =
      mostRecentTicket.mostRecentTicket?.relationships?.supportRequestProvider
        .data.id;
    const status = mostRecentTicket.mostRecentTicket?.attributes?.state;
    const caseId =
      mostRecentTicket.mostRecentTicket?.relationships?.surgeryCase.data.id;
    const jiraId = getTicketJiraId(
      caseTickets.responseData,
      mostRecentTicketId
    );

    setTicketJiraInfoFunction({
      caseId: caseId,
      ticketId: jiraId,
      status: status
    });
  }
};
