import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip, message } from "antd";
import { CopyOutlined, CloseOutlined } from "@ant-design/icons";
import { copyToClipboard } from "gsi-ui-components";
import { triggerShareClear } from "../../actions/acceptCase";
import "./CaseCloningAlert.less";

export const showClonedCaseStatus = (
  key,
  caseNumber = null,
  triggerShareClearFunction = null,
  setCloneStatusKeyFunction = null
) => {
  if (key && caseNumber) {
    message.success({
      content: (
        <div>
          Case cloned&nbsp;
          <span className="case-number">
            {`#${caseNumber}`}
            <Tooltip title="Copy">
              <CopyOutlined
                onClick={async () => {
                  await copyToClipboard(caseNumber, "Case number", message);
                }}
              />
            </Tooltip>
          </span>
          <CloseOutlined
            onClick={() => {
              message.destroy(key);
              setCloneStatusKeyFunction(null);
              triggerShareClearFunction();
            }}
          />
        </div>
      ),
      className: "success-message",
      duration: 0,
      key
    });
  } else if (key) {
    message.loading({ content: "Cloning case...", duration: 0, key });
  } else {
    message.destroy();
  }
};

const CaseCloningAlert = ({
  websocketUpdate,
  acceptCaseInfo,
  triggerShareClear
}) => {
  const [cloneStatusKey, setCloneStatusKey] = useState(null);
  const [cloneCaseNumber, setCloneCaseNumber] = useState(null);

  useEffect(() => {
    setCloneStatusKey(null);
    triggerShareClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (websocketUpdate.message.caseNumber) {
      setCloneCaseNumber(websocketUpdate.message.caseNumber);
      showClonedCaseStatus(
        cloneStatusKey,
        websocketUpdate.message.caseNumber,
        triggerShareClear,
        setCloneStatusKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUpdate]);

  useEffect(() => {
    setCloneStatusKey(acceptCaseInfo.messageRef);
    showClonedCaseStatus(
      acceptCaseInfo.messageRef,
      cloneCaseNumber,
      triggerShareClear,
      setCloneStatusKey
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptCaseInfo]);

  return null;
};

CaseCloningAlert.propTypes = {
  websocketUpdate: PropTypes.object,
  acceptCaseInfo: PropTypes.object,
  triggerShareClear: PropTypes.func
};

const mapStateToProps = state => {
  return {
    websocketUpdate: state.websocket,
    acceptCaseInfo: state.acceptCase
  };
};

export default connect(mapStateToProps, { triggerShareClear })(
  CaseCloningAlert
);
