import { cloneDeep } from "lodash";
import { ctScanType } from "gsi-ui-components";
import { CT_SCAN } from "../helpers/types";
import implantsInfo from "../resources/common/implantsInfo.json";

export const getTotalCases =
  (paramUrl = "") =>
  async dispatch => {
    const [{ CASE_COUNT }, { sendGetRequest }, { getTotalCasesUrl }] =
      await Promise.all([
        import(`../helpers/types`),
        import(`../helpers/URLHelper`),
        import("gsi-ui-components")
      ]);

    try {
      dispatch({
        type: CASE_COUNT.LOADING
      });

      const response = await sendGetRequest(
        `${getTotalCasesUrl()}${paramUrl}`,
        null,
        true
      );
      const totalCases = response.data.count;

      dispatch({
        type: CASE_COUNT.SUCCESS,
        payload: totalCases
      });
    } catch (error) {
      dispatch({
        type: CASE_COUNT.ERROR
      });
    }
  };

export const getCaseList =
  (paramUrl = null) =>
  async dispatch => {
    const [
      { CASE_LIST },
      { sendGetRequest, getDefaultParams },
      { getCaseByParametersUrl, PAGINATION_SIZE, API_RESOURCE }
    ] = await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/URLHelper`),
      import("gsi-ui-components")
    ]);

    dispatch({
      type: CASE_LIST.LOADING
    });

    try {
      const params = [
        ...getDefaultParams(0, PAGINATION_SIZE),
        {
          param: API_RESOURCE.PARAM_SORT_BY,
          value: `lastModifiedPlanningDate:${API_RESOURCE.PARAM_SORT_BY_ORDER_DESC}`
        }
      ];

      const response = await sendGetRequest(
        paramUrl ? paramUrl : getCaseByParametersUrl(params),
        null,
        true
      );

      const responseData = response.data;

      const sharedCases = await getSharedCases();

      dispatch({
        type: CASE_LIST.SUCCESS,
        payload: { responseData, sharedCases }
      });
    } catch (error) {
      dispatch({
        type: CASE_LIST.ERROR
      });
    }
  };

const getSharedCases = async () => {
  const [{ getSharedCaseListUrl }, { sendGetRequest }] = await Promise.all([
    import(`gsi-ui-components`),
    import(`../helpers/URLHelper`)
  ]);

  const sharedCases = [];

  try {
    const response = await sendGetRequest(getSharedCaseListUrl());
    const data = response.data.data;

    for (let entry of data) {
      sharedCases.push(entry.attributes.targetCaseNumber);
    }
  } catch (error) {
    return sharedCases;
  }

  return sharedCases;
};

export const clearCaseList = () => async dispatch => {
  const { CASE_LIST } = await import(`../helpers/types`);

  dispatch({
    type: CASE_LIST.CLEAR
  });
};

export const getCaseInfo = caseNumber => async dispatch => {
  const [{ CASE_INFO }, { getCaseByParametersUrl }, { sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);

  try {
    dispatch({
      type: CASE_INFO.LOADING
    });

    const params = [{ param: "caseNumber", value: caseNumber }];

    const response = await sendGetRequest(
      getCaseByParametersUrl(params),
      null,
      true
    );
    const responseCaseData = response.data.entities[0];

    dispatch({
      type: CASE_INFO.SUCCESS,
      payload: responseCaseData
    });
  } catch (error) {
    dispatch({
      type: CASE_INFO.ERROR
    });
  }
};

export const clearCaseInfo = () => async dispatch => {
  const { CASE_INFO } = await import(`../helpers/types`);

  dispatch({
    type: CASE_INFO.CLEAR
  });
};

export const getPlanningInfo = planningUri => async dispatch => {
  const [{ PLANNING_INFO }, { getPlanningUrl }, { sendGetRequest }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`gsi-ui-components`),
      import(`../helpers/URLHelper`)
    ]);

  try {
    const planningData = [];
    let planningReference;

    dispatch({
      type: PLANNING_INFO.LOADING
    });

    for (planningReference of planningUri) {
      const response = await sendGetRequest(
        getPlanningUrl(planningReference.id),
        null,
        true
      );
      const responseData = response.data.entities[0];

      planningData.push(responseData);
    }

    planningData.sort(
      (planningA, planningB) =>
        planningA.data.PlanData.idx - planningB.data.PlanData.idx
    );

    dispatch({
      type: PLANNING_INFO.SUCCESS,
      payload: planningData
    });
  } catch (error) {
    dispatch({
      type: PLANNING_INFO.ERROR
    });
  }
};

export const clearPlanningInfo = () => async dispatch => {
  const { PLANNING_INFO } = await import(`../helpers/types`);

  dispatch({
    type: PLANNING_INFO.CLEAR
  });
};

export const setCTScanPreview = ctsPreview => {
  return {
    type: CT_SCAN.SUCCESS,
    payload: {
      axialPreview: ctsPreview.axialPlainText,
      coronalPreview: ctsPreview.coronalPlainText
    }
  };
};

export const getCTScanPreview =
  ({ firstId, secondId }) =>
  async dispatch => {
    try {
      dispatch({ type: CT_SCAN.LOADING });

      const { getCTScanById, sendGetRequest } = await import(
        `../helpers/URLHelper`
      );

      const firstCTScanRequest = sendGetRequest(getCTScanById(firstId));
      const secondCTScanRequest = sendGetRequest(getCTScanById(secondId));

      const [firstCTScanResponse, secondCTScanResponse] = await Promise.all([
        firstCTScanRequest,
        secondCTScanRequest
      ]);

      const filteredScans = filterScans(
        firstCTScanResponse.data.entities[0],
        secondCTScanResponse.data.entities[0]
      );

      dispatch({
        type: CT_SCAN.SUCCESS,
        payload: {
          axialPreview: filteredScans[0].binaryCTScan,
          coronalPreview: filteredScans[1].binaryCTScan
        }
      });
    } catch (error) {
      setCTScanPreviewError();
    }
  };

export const resetCTScanPreviewStore = () => {
  return {
    type: CT_SCAN.RESET
  };
};

export const setCTScanPreviewError = () => dispatch => {
  dispatch({
    type: CT_SCAN.ERROR
  });
};

const filterScans = (firstGroup, secondGroup) => {
  if (!firstGroup.ctScanType || !secondGroup.ctScanType) {
    return [firstGroup, secondGroup];
  }
  if (firstGroup.ctScanType === ctScanType.AXIAL) {
    return [firstGroup, secondGroup];
  }
  return [secondGroup, firstGroup];
};

export const loadImplantsInfo = () => async dispatch => {
  const [{ LOAD_IMPLANTS_INFO }, { sendGetRequest }, { getImplantsInfoUrl }] =
    await Promise.all([
      import(`../helpers/types`),
      import(`../helpers/URLHelper`),
      import(`gsi-ui-components`)
    ]);

  dispatch({
    type: LOAD_IMPLANTS_INFO.LOADING
  });
  try {
    const response = await sendGetRequest(getImplantsInfoUrl());

    const data = response.data;
    const implantsConfigurationData =
      data.entities[0].implantsConfigurationData;

    const retrievedImplantsInfo = cloneDeep(implantsConfigurationData);

    dispatch({
      type: LOAD_IMPLANTS_INFO.SUCCESS,
      payload: retrievedImplantsInfo
    });
  } catch (error) {
    console.error(
      `While retrieving implants info from Surgery Core: ${error}. Trying locally... `
    );
    await dispatch(getLocalImplantsInfo());
  }
};

export const getLocalImplantsInfo = () => async dispatch => {
  const { LOAD_IMPLANTS_INFO } = await import(`../helpers/types`);

  try {
    dispatch({
      type: LOAD_IMPLANTS_INFO.SUCCESS,
      payload: cloneDeep(implantsInfo)
    });
  } catch (error) {
    console.error(`While retrieving implants info locally: ${error}`);
    dispatch({
      type: LOAD_IMPLANTS_INFO.ERROR
    });
  }
};
