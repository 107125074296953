import Keycloak from "keycloak-js";
import jwt from "jwt-decode";
import { init, setUser, setTag, configureScope } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const keycloakInstance = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
});

const initKeycloak = onAuthenticatedCallback => {
  keycloakInstance.init({ onLoad: "check-sso" }).then(async () => {
    onAuthenticatedCallback();

    const { portalSentryURL } = await import(`./URLHelper`);

    init({
      dsn: process.env.REACT_APP_SENTRY_URL || portalSentryURL,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0
    });

    setUser({
      id: getUserId(),
      username: getName(),
      email: getEmail(),
      ip_address: "{{auto}}"
    });

    setTag("username", getName());
    setTag("userID", getUserId());
    configureScope(scope => {
      scope.setTag("session", getSessionId());
    });
  });
};

const getSecondsToExpireToken = token => {
  const tokenExpiresInEpoch = jwt(token).exp;
  const tokenExpiresIn = new Date(0).setUTCSeconds(tokenExpiresInEpoch);
  const localEpoch = Date.now();
  return Math.floor((tokenExpiresIn - localEpoch) / 1000);
};

const isTokenExpired = () => {
  const accessToken = getToken();
  const secondsToExpireToken = getSecondsToExpireToken(accessToken);

  return secondsToExpireToken <= 1;
};

const isLoggedIn = () => !!keycloakInstance.token;

const getUserId = () => keycloakInstance.tokenParsed?.sub;

const getName = () => keycloakInstance.tokenParsed?.name;

const getEmail = () => keycloakInstance.tokenParsed?.email;

const getSessionId = () => keycloakInstance.tokenParsed?.session_state;

const getToken = () => keycloakInstance.token;

const refreshToken = () => keycloakInstance.updateToken();

const logout = keycloakInstance.logout;

const userService = {
  initKeycloak,
  isLoggedIn,
  getUserId,
  getName,
  getEmail,
  getSessionId,
  getToken,
  logout,
  isTokenExpired,
  refreshToken
};

export default userService;
