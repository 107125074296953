import { isEmpty } from "lodash";
import { TICKET_STATUS } from "gsi-ui-components";

export const getTicketJiraId = (responseData, ticketId) => {
  let jiraKey = null;

  responseData.included &&
    responseData.included.forEach(value => {
      if (value.type === "supportRequestProvider" && value.id === ticketId) {
        jiraKey = value.attributes.key;
      }
    });

  return jiraKey;
};

export const getMostRecentOpenTicket = ticketsArray => {
  let ticket, isInProcess;

  if (!isEmpty(ticketsArray)) {
    let index = ticketsArray.length - 1;

    for (index; index >= 0; index--) {
      const mostRecentTicket = ticketsArray[index];
      const mostRecentTicketState = mostRecentTicket?.attributes.state;
      isInProcess = mostRecentTicketState !== TICKET_STATUS.DONE;

      if (isInProcess) {
        ticket = mostRecentTicket;
        break;
      }
    }
  }

  return { mostRecentTicket: ticket, isInProcess: isInProcess };
};
